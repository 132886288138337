import React, {useEffect} from 'react';
import {Form, ListGroup} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {getAnalyzersAPI} from "../../api/dashboard/dashboard";
import {setAnalyzersReducer} from "../../redux/reducers/docFilters";

const Analyzers = ({selected, setSelected}) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);
    const analyzers = useSelector((state) => state.docFilters.analyzers);

    const selectAll = () => {
        if (selected.length >= analyzers.length) {
            setSelected([])
        } else {
            setSelected(analyzers)
        }
    }

    const selectOne = (a) => {
        if (selected.some(s => s.uuid === a.uuid)) {
            setSelected(prev => prev.filter(s => s.uuid !== a.uuid))
        } else {
            setSelected(prev => [...prev, a])
        }
    }

    useEffect(() => {
        getAnalyzersAPI(user.active_departments.id).then((data) => {
            if (data) {
                dispatch(setAnalyzersReducer(data.results))
                setSelected(data.results)
            }
        })
    }, [user.active_departments]);


    return (
        <ListGroup>
            <ListGroup.Item className={'fw-bold d-flex align-items-center'}>
                <Form.Check>
                    <Form.Check.Input disabled={analyzers.length < 1} type={'checkbox'} checked={selected.length === analyzers.length && analyzers.length > 0} onChange={selectAll} id={'allAnalyzers'} className={'me-2'} />
                    <Form.Check.Label for={'allAnalyzers'}>Анализаторы</Form.Check.Label>
                </Form.Check>
                <span></span>
            </ListGroup.Item>
            {analyzers.map((analyzer) => (
                <ListGroup.Item key={analyzer.uuid}>
                    <Form.Check>
                        <Form.Check.Input type={'checkbox'} checked={selected.some(s => s.uuid === analyzer.uuid)} onChange={() => selectOne(analyzer)} id={analyzer.uuid}></Form.Check.Input>
                        <Form.Check.Label for={analyzer.uuid}>{analyzer.name}</Form.Check.Label>
                    </Form.Check>
                </ListGroup.Item>
            ))}
        </ListGroup>
    );
};

export default Analyzers;