export const dateFormat = (date) => {
        const today = new Date(date);
        let yyyy = today.getFullYear();
        let month = today.getMonth() + 1;

        let dd = today.getDate();
        let hh = today.getHours();
        let mm = today.getMinutes();

        if (dd < 10) dd = '0' + dd;
        if (month < 10) month = '0' + month;
        if (hh < 10) mm = '0' + hh;
        if (mm < 10) mm = '0' + mm;
        if (!date) {
            return 'нет даты';
        }
        return dd + '.' + month + '.' + String(yyyy).slice(2) + ' г.';
}

export const dateFormatWithoutTime = (date) => {
        date = date.split('-')
        const yyyy = date[0]
        const mm = date[1]
        const dd = date[2]

        return dd + '.' + mm + '.' + String(yyyy) + ' г.';
}