import React, {useContext, useEffect, useState} from 'react';
import {Accordion, AccordionContext, Button, Card, ListGroup, useAccordionButton} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {getDocFilesAPI} from "../../../../api/dashboard/dashboard";
import {dateFormat} from "../../../../funcs/funcs";



function ContextAwareToggle({ eventKey, callback, f }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    const clickHandler = (e) => {
        if (e.target.tagName.toLowerCase() !== 'button') {
            decoratedOnClick(e)
        }
    }

    const openFile = () => {
        if (f.document.integration.name === 'regulation.gov.ru') {
            window.open(`https://regulation.gov.ru/FileData/GetDocContent/${f.id_file}`)
        } else {
            alert('Файл недоступен для полного просмотра')
        }
    }

    return (
        <div
            className={'w-100 d-flex align-items-center justify-content-between cursor-pointer'}
            onClick={clickHandler}
        >
            <div className={'d-flex align-items-center gap-2'}>
                <div className={'d-flex'}>
                    <i className={'bi bi-caret-right-fill'} style={{transform: isCurrentEventKey ? 'rotate(90deg)' : 'rotate(0)', transition: 'transform 0.3s ease'}}></i>
                </div>
                <p className={'m-0 fw-bold lh-1'}>{f.name}</p>
            </div>
            <div className={'d-flex gap-2 align-items-center me-2'}>
                <p className={'m-0 opacity-50 small lh-1'}>{dateFormat(f.created)}</p>
                <Button size={'sm'} variant={'light'} onClick={openFile}>Открыть в новом окне</Button>
            </div>
        </div>
    );
}

const Files = () => {
    const params = useParams()
    const [files, setFiles] = useState([])

    useEffect(() => {
        getDocFilesAPI(params.docId).then(res => {
            if (res) {
                setFiles(res.results)
            }
        })
    }, []);

    return (
        <div className={'flex-grow-1 overflow-y-auto'}>
            <Accordion>
            {files.map(f =>
                <Card className={'mb-2 border-0 shadow-sm'}>
                    <Card.Header className={'border-0'}>
                        <ContextAwareToggle eventKey={f.id_file} f={f} />
                    </Card.Header>
                    <Accordion.Collapse eventKey={f.id_file}>
                        <Card.Body>
                                <div>{f.text_document}</div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            )}
            </Accordion>
        </div>
    );
};

export default Files;