import React from 'react';

const CreateInt = () => {
    return (
        <div>
            <h5 className={'fw-bold mb-3'}>Создать интеграцию</h5>
            <p className={'fw-bold mb-2'}>Настройка автоматической синхронизации из сайтов</p>
            <p>Настройте автоматическую синхронизацию наиболее часто используемых юристами сайтов и новостных служб.
                Если при настройке коннектора не указано иное, мы будем получать последние обновления из источника каждые 10 минут.</p>
            <p className={'fw-bold mt-4 mb-2'}>Системные</p>
            <div className={'d-flex flex-wrap gap-2'}>
                <div className={'bg-light shadow rounded-4 py-3 d-flex flex-column align-items-center justify-content-between px-3'} style={{height: '120px', width: '150px'}}>
                    <div className={'flex-grow-1 d-flex align-items-center'}>
                        <img src="/images/rnlegal.svg" alt="" width={'100%'}/>
                    </div>
                    <p className={'m-0 mt-3 small text-truncate mw-100'}>regulation.gov.ru</p>
                </div>
            </div>
            <p className={'fw-bold mt-4 mb-2'}>Пользовательские</p>
            <div className={'d-flex flex-wrap gap-2'}>
                <div className={'bg-light shadow rounded-4 py-3 d-flex flex-column align-items-center justify-content-between px-3'} style={{height: '120px', width: '150px'}}>
                    <div className={'flex-grow-1 d-flex align-items-center'}>
                        <img src="/images/rnlegal.svg" alt="" width={'100%'}/>
                    </div>
                    <p className={'m-0 mt-3 small text-truncate mw-100'}>Python</p>
                </div>
            </div>
        </div>
    );
};

export default CreateInt;