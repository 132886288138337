import React, {useState} from 'react';
import {Badge, Button, Dropdown, Nav, Navbar} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {exitAPI} from "../../api/auth/auth";
import {useDispatch, useSelector} from "react-redux";
import {setUserReducer} from "../../redux/reducers/user";
import {setUpdUserAPI} from "../../api/dashboard/dashboard";

const NavBar = () => {
    const user = useSelector(state => state.user.user)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const links = [
        {id: 1, title: 'Все документы', url: '/dashboard'},
        // {id: 2, title: 'Изменения', url: '#'},
        // {id: 3, title: 'Задачи', url: '#'},
        // {id: 4, title: 'Настройки', url: '/settings'}
    ]

    const selectDepartment = (id) => {
        setUpdUserAPI({active_departments: id}).then(res => {
            if (res) {
                dispatch(setUserReducer(res))
            }
        })
    }

    const exit = () => {
        exitAPI().then(res => {
            if (res) {
                navigate('/')
            }
        })
    }

    return (
        <Navbar className={'px-5 justify-content-between shadow-sm'}>
            <Nav className={'align-items-center'}>
            <Navbar.Brand href={'/'} className={'me-5'}><img src="/images/rnlegal.svg" alt="logo" height={'28px'}/></Navbar.Brand>
            {links.map(l =>
                <Nav.Item key={l.id} className={'me-3'}><Link className={'text-decoration-none text-dark'} to={l.url}>{l.title}</Link></Nav.Item>
            )}
            </Nav>
            <Nav className={'align-items-center'}>
                {user &&
                    <Dropdown onSelect={selectDepartment} className={'me-2'}>
                        <Dropdown.Toggle variant={'light'}>{user?.active_departments?.name}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {user?.departments?.map(d => (
                                <Dropdown.Item eventKey={d.id}>{d.name}</Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                }
                <Button variant={'light'} className={'rounded-circle position-relative me-3'}>
                    <i className={'bi bi-bell-fill cursor-pointer'}><Badge className={'bg-warning text-sm position-absolute top-0 start-100 translate-middle rounded-pill'}>12</Badge></i>
                </Button>
                <Dropdown>
                    <Dropdown.Toggle variant={'light'} className={'d-flex align-items-center'}><i className={'bi bi-person-circle me-2'}></i>{user?.first_name + ' ' + user?.last_name}</Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={exit}>
                            Выход
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Nav>
        </Navbar>
    );
};

export default NavBar;