import {api} from "../api";

const urls = {
    docs: '/document/?page=number',
    oneDoc: '/document/uuid/',
    analysers: '/analyzer/?departments_analyzer__id=depId',
    statuses: '/status/',
    statusDoc: '/document/uuid/',
    docFiles: '/file/?document=uuid',
    docAnalyses: '/analyse/?file__document=uuid',
    analyse: '/analyse/',
    oneAnalyse: '/analyse/uuid/',
    users: '/user/',
    updUser: '/changemi'
}

export const getDocumentsAPI = async (status, search, analyzers, page = 1) => {
    try {
        const res = await api.get(urls.docs.replace('number', String(page)) + status.map(s => `&${s.action}=${s.uuid}`).join('') + (analyzers.length > 0 ? analyzers.map(a => `&analizers=${a.uuid}`).join('') : '') + (search ? `&search=${search}` : ''))
        const data = await res
        return data.data
    } catch (e) {

    }
}

export const getDocFilesAPI = async (uuid) => {
    try {
        const res = await api.get(urls.docFiles.replace('uuid', uuid))
        const data = await res
        return data.data
    } catch (e) {

    }
}

export const getDocAnalysesAPI = async (uuid) => {
    try {
        const res = await api.get(urls.docAnalyses.replace('uuid', uuid))
        const data = await res
        return data.data
    } catch (e) {

    }
}

export const setUpdUserAPI = async (body) => {
    try {
        const res = await api.patch(urls.updUser, body);
        const data = await res
        return data.data
    } catch (e) {

    }
}

export const getOneDocumentAPI = async (uuid) => {
    try {
        const res = await api.get(urls.oneDoc.replace('uuid', uuid))
        const data = await res
        return data.data
    } catch (e) {

    }
}
export const getAnalyzersAPI = async (id) => {
    try {
        const res = await api.get(urls.analysers.replace('depId', id))
        const data = await res
        return data.data
    } catch (e) {

    }
}

export const getAllUsersAPI = async (uuid) => {
    try {
        const res = await api.get(urls.users)
        const data = await res
        return data.data
    } catch (e) {

    }
}

export const getStatusesAPI = async () => {
    try {
        const res = await api.get(urls.statuses)
        const data = await res
        return data.data
    } catch (e) {

    }
}

export const setStatusToDocAPI = async (uuid, status) => {
    try {
        const res = await api.patch(urls.statusDoc.replace('uuid', uuid), {
            status_id: status.uuid
        })
        const data = await res
        return data.data
    } catch (e) {

    }
}

export const updDocAPI = async (uuid, form) => {
    try {
        const res = await api.patch(urls.statusDoc.replace('uuid', uuid), form)
        const data = await res
        return data.data
    } catch (e) {

    }
}

export const createAnalyseAPI = async (form) => {
    try {
        const res = await api.post(urls.analyse, form)
        const data = await res
        return data.data
    } catch (e) {

    }
}

export const updAnalyseAPI = async (uuid, form) => {
    try {
        const res = await api.patch(urls.oneAnalyse.replace('uuid', uuid), form)
        const data = await res
        return data.data
    } catch (e) {

    }
}