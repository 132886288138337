import React, {useEffect, useState} from 'react';
import {Button, Form, Modal} from "react-bootstrap";

const EditModal = ({show, close, item, save}) => {
    const [info, setInfo] = useState({name: '', worlds: ''})

    const handleInfo = (e) => {
        setInfo(prev => Object.assign({...prev}, {[e.target.name]: e.target.value}))
    }

    useEffect(() => {
        setInfo(item)
    }, [show, item]);

    const sendInfo = (e) => {
        e.preventDefault()
        save(info)
    }

    return (
        <Modal show={show} onHide={close} size={'xl'}>
            <Modal.Body>
                <form onSubmit={sendInfo}>
                    <p className={'fw-bold m-0'}>Название</p>
                    <Form.Control name={'name'} required className={'mb-3'} value={info?.name} onChange={handleInfo} />
                    <p className={'fw-bold m-0'}>Слова</p>
                    <Form.Control name={'worlds'} required as={'textarea'} value={String(info?.worlds)} rows={8} className={'mb-3'} onChange={handleInfo} />
                    <Button variant={'success'} type={'submit'}>Сохранить</Button>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default EditModal;