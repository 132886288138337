import React, {useEffect, useState} from 'react';
import {Button, Col, Dropdown, Form, InputGroup, Row} from "react-bootstrap";
import DashTable from "./DashTable";
import {useDispatch, useSelector} from "react-redux";
import {getDocumentsAPI, setStatusToDocAPI} from "../../api/dashboard/dashboard";
import {setNewDocs, updDocReducer} from "../../redux/reducers/docs";
import UserProvider from "../../providers/UserProvider";
import Analyzers from "./Analyzers";

const Layout = () => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user.user);
    const [checkedDocs, setCheckedDocs] = useState([])
    const [analyzers, setAnalyzers] = useState([]);
    const statuses = useSelector(state => state.statuses.statuses)
    const [loading, setLoading] = useState(true)
    const [searchInput, setSearchInput] = useState('')
    const [search, setSearch] = useState('')
    const [filters, setFilters] = useState([])

    const getDocs = (filter) => {
        setLoading(true)
        getDocumentsAPI(filter, search, analyzers).then(res => {
            if (res?.results) {
                dispatch(setNewDocs(res))
            }
            setLoading(false)
        })
    }

    const handleStatusFilter = (e, s) => {
        s = {action: 'status', uuid: s?.uuid}
        let newFilters =  filters.some(u => u.uuid === s.uuid) ? filters.filter((u => u.uuid !== s.uuid)) : [...filters, s]
        localStorage.setItem('filters', JSON.stringify(newFilters))
        setFilters(newFilters)
        getDocs(newFilters)
    }

    const handleOtherFilter = (a) => {
        let newFilters = filters.some(s => s.action === a) ? filters.filter(s => s.action !== a) : [...filters, {action: a, uuid: true}]
        localStorage.setItem('filters', JSON.stringify(newFilters))
        setFilters(newFilters)
        getDocs(newFilters)
    }

    const setStatusForChecked = (s) => {
        checkedDocs.forEach(doc => {
            setStatusToDocAPI(doc.uuid, s).then(res => {
                if (res) {
                    dispatch(updDocReducer([res]))
                }
            })
        })
    }

    const handleSearchInput = (e) => {
        if (e.keyCode === 13) {
            setSearch(searchInput)
        }
    }

    useEffect(() => {
        if (localStorage.getItem('filters')) {
            setFilters(JSON.parse(localStorage.getItem('filters')))
            getDocs(JSON.parse(localStorage.getItem('filters')))
        } else if (statuses.length > 0) {
            let defaultFilters = [...statuses.filter(s => s?.slug === 'unprocessed' || s?.slug === 'monitoring').map(s => ({action: 'status', uuid: s?.uuid})), {action: 'relevant_is_true', uuid: true}]
            setFilters(defaultFilters)
            getDocs(defaultFilters)
        }
    }, [statuses, analyzers, search, user?.active_departments?.id])

    return (
        <UserProvider>
            <div className={'bg-light d-flex flex-column flex-grow-1 px-5 py-3'}>
                <Row className={'m-0'}>
                    <Col md={3} className={'ps-0'}>
                            <InputGroup className={'w-100'}>
                                <InputGroup.Text className={'bg-secondary-subtle rounded-start-pill'}>
                                    Быстрое действие
                                </InputGroup.Text>
                                <Dropdown className={'position-relative'}>
                                    <Dropdown.Toggle variant={'light'} className={'bg-white rounded-end-pill flex-grow-1 d-flex justify-content-between align-items-center'}>Выберите статус...</Dropdown.Toggle>
                                    <Dropdown.Menu className={'p-2'} align={'end'}>
                                        {statuses.map(s =>
                                            <Dropdown.Item onClick={() => setStatusForChecked(s)}>{s.name}</Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </InputGroup>
                    </Col>
                    <Col className={'p-0 d-flex'}>
                        <InputGroup className={'border rounded-pill me-2'}>
                            <InputGroup.Text className={'rounded-start-pill border-0 bg-white'} ><i className={'bi bi-search'}></i></InputGroup.Text>
                            <Form.Control value={searchInput} onKeyDown={handleSearchInput} onChange={(e) => setSearchInput(e.target.value)} className={'rounded-0 border-0 shadow-none ps-0'} placeholder={'Поиск документов'}/>
                            <Button variant={'light'} className={'border-0 rounded-end-pill bi bi-arrow-return-left'} onClick={() => setSearch(searchInput)}></Button>
                        </InputGroup>
                        <Dropdown autoClose={'outside'}>
                            <Dropdown.Toggle variant={'light'} bsPrefix={'bi bi-gear-fill bg-secondary-subtle rounded-circle'} />
                            <Dropdown.Menu className={"p-2 w-auto"}>
                                <b>Статусы</b>
                                <Dropdown.Divider />
                                {statuses.map(s =>
                                    <Form.Check checked={filters.some(u => u.uuid === s.uuid)} onChange={(e) => handleStatusFilter(e, s)} type="switch" id={s.name} label={s.name} />
                                )}
                                <b>Анализы</b>
                                <Dropdown.Divider />
                                <Form.Check checked={filters.find(s => s.action === 'relevant_is_true')?.uuid} onChange={() => handleOtherFilter('relevant_is_true')} type="switch" id="custom-switch" label="Релевантен" />
                                <Form.Check checked={filters.find(s => s.action === 'word_is')?.uuid} onChange={() => handleOtherFilter('word_is')} type="switch" id="custom-switch2" label="Ключевые слова" />
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                <Row className={'mt-3 flex-grow-1 m-0'}>
                    <Col lg={3}>
                        <Analyzers selected={analyzers} setSelected={setAnalyzers} />
                    </Col>
                    <Col lg={9} className={'d-flex p-0'}>
                        <DashTable filters={filters} analyzers={analyzers} search={search} checkedDocs={checkedDocs} setCheckedDocs={setCheckedDocs} loading={loading} />
                    </Col>
                </Row>
            </div>
        </UserProvider>
    );
};

export default Layout;