import React, {useEffect, useState} from 'react';
import {Button, Dropdown, Form, ListGroup} from "react-bootstrap";
import {getAllUsersAPI, updDocAPI} from "../../../../api/dashboard/dashboard";
import {useSelector} from "react-redux";

const Users = ({doc, updStatus, updUsersFunc}) => {
    const statuses = useSelector(state => state.statuses.statuses)
    const [allUsers, setAllUsers] = useState([])
    const [checked, setChecked] = useState([])
    const [search, setSearch] = useState('')

    const getSearchUsers = (u) => {
        const keys = ['last_name', 'first_name', 'email']
        return keys.map(k => u[k].toLowerCase().includes(search.toLowerCase())).includes(true)
    }

    const checkUser = (u) => {
        let newUsers = !checked.some(c => c.uuid === u.uuid) ? [...checked, u] : checked.filter(a => a.uuid !== u.uuid)
        setChecked(newUsers)
        updUsersFunc(doc.uuid, newUsers.map(n => n.uuid))
    }

    const changeStatus = (s) => {
        updStatus(doc, s)
    }

    useEffect(() => {
        getAllUsersAPI().then(res => {
            if (res) {
                setAllUsers(res.results)
            }
        })
    }, []);

    useEffect(() => {
        setChecked(doc.get_users)
    }, [doc.get_users]);

    return (
        <div className={'rounded shadow-sm w-100 mb-3 border p-3'}>
            <p className={'m-0 fw-bold mb-2'}>Статус</p>
                <Dropdown className={'mb-3'}>
                    <Dropdown.Toggle variant={'light'} size={'sm'} className={'w-100'}>{doc?.status?.name}</Dropdown.Toggle>
                    <Dropdown.Menu>
                        {statuses.map(s =>
                            <Dropdown.Item onClick={() => changeStatus(s)}>{s.name}</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            <p className={'m-0 fw-bold mb-2'}>Пользователи</p>
            <ListGroup className={'mb-3'}>
                {doc.get_users.length > 0 ? doc.get_users.map(u =>
                        <ListGroup.Item>
                            <div>
                                <p className={'m-0'}>{u.first_name} {u.last_name}</p>
                                <p className={'m-0 small opacity-50'}>{u.email}</p>
                            </div>
                        </ListGroup.Item>
                    )
                    :
                    <ListGroup.Item className={'opacity-50'}>Пользователи не выбраны</ListGroup.Item>
                }
                <ListGroup.Item className={'p-0'}>
                    <Dropdown autoClose={'outside'}>
                        <Dropdown.Toggle variant={'light'} size={'sm'} className={'w-100 rounded-top-0'}>Выбрать пользователей</Dropdown.Toggle>
                            <Dropdown.Menu className={'w-100'}>
                                <Dropdown.ItemText className={'px-2'}>
                                    <Form.Control placeholder={'Поиск'} className={'w-100 shadow-none border'} value={search} onChange={(e) => setSearch(e.target.value) } />
                                </Dropdown.ItemText>
                                {allUsers.filter(u => getSearchUsers(u)).length > 0 ? allUsers.filter(u => getSearchUsers(u)).map(u =>
                                    <Dropdown.Item onClick={() => checkUser(u)}>
                                        <Form.Check>
                                            <Form.Check.Input id={u.uuid} checked={checked.some(a => a.uuid === u.uuid)} onClick={e => e.preventDefault()} onChange={e => e.preventDefault()} />
                                            <Form.Check.Label for={u.uuid}>
                                                <div>
                                                    <p className={'m-0'}>{u.first_name} {u.last_name}</p>
                                                    <p className={'m-0 small opacity-50'}>{u.email}</p>
                                                </div>
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </Dropdown.Item>
                                )
                                :
                                <Dropdown.ItemText className={'text-center text-muted'}>Пользователи не найдены</Dropdown.ItemText>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                </ListGroup.Item>
            </ListGroup>
        </div>
    );
};

export default Users;