import {api} from "../api";

const urls = {
    models: '/aimodel/',
    listword: '/listword/',
    stopword: '/stopword/',
    analyzer: '/analyzer/'
}

export const getModels = async () => {
    try {
        const res = await api.get(urls.models)
        const data = await res
        return data.data
    } catch (e) {

    }
}

export const getAnalyzers = async () => {
    try {
        const res = await api.get(urls.analyzer)
        const data = await res
        return data.data
    } catch (e) {

    }
}

export const getListWordAPI = async () => {
    try {
        const res = await api.get(urls.listword)
        const data = await res
        return data.data
    } catch (e) {

    }
}

export const getStopListAPI = async () => {
    try {
        const res = await api.get(urls.stopword)
        const data = await res
        return data.data
    } catch (e) {

    }
}

export const createAntIntAPI = async (form) => {
    try {
        const res = await api.post(urls.analyzer, {
            aimodel_id: form.model,
            name: form.name,
            system_prompt: form.sysPrompt,
            task_prompt: form.taskPrompt,
            comments: form.commentCheck,
            analise_if_is_keywords: form.filterCheck,
            api_key: form.key,
            size_request: form.textSize
        })
        const data = await res
        return data.data
    } catch (e) {

    }
}

export const createStopWord = async (form) => {
    try {
        const res = await api.post(urls.stopword, {name: form.name, worlds: form.worlds.split(',')})
        const data = await res
        return data.data
    } catch (e) {

    }
}

export const createListWord = async (form) => {
    try {
        const res = await api.post(urls.listword, {name: form.name, worlds: form.worlds.split(',')})
        const data = await res
        return data.data
    } catch (e) {

    }
}
export const updListWord = async (form) => {
    try {
        const res = await api.patch(urls.listword + form.uuid + '/', {name: form.name, worlds: form.worlds.split(',')})
        const data = await res
        return data.data
    } catch (e) {

    }
}

export const updStopWord = async (form) => {
    try {
        const res = await api.patch(urls.stopword + form.uuid + '/', {name: form.name, worlds: form.worlds.split(',')})
        const data = await res
        return data.data
    } catch (e) {

    }
}

export const deleteStopWord = async (uuid) => {
    try {
        const res = await api.delete(urls.stopword + uuid + '/')
        const data = await res
        return data.status === 204
    } catch (e) {

    }
}

export const deleteListWord = async (uuid) => {
    try {
        const res = await api.delete(urls.listword + uuid + '/')
        const data = await res
        return data.status === 204
    } catch (e) {

    }
}