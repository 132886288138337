import {createSlice} from "@reduxjs/toolkit";

export const docFilters = createSlice({
    name: 'docFilters',
    initialState: {
        activeDepartment: null,
        departments: [],
        analyzers: []
    },
    reducers: {
        setActiveDepartmentReducer: (state, action) => {
            state.activeDepartment = action.payload
        },
        setDepartmentsReducer: (state, action) => {
            state.departments = action.payload
        },
        setAnalyzersReducer: (state, action) => {
            state.analyzers = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setActiveDepartmentReducer, setDepartmentsReducer, setAnalyzersReducer } = docFilters.actions

export default docFilters.reducer