import React from 'react';
import NavBar from "../components/Navbar/NavBar";
import Layout from "../components/Settings/Layout";

const Settings = () => {
    return (
        <div className={'w-100 h-100 bg-light d-flex flex-column overflow-hidden'}>
            <NavBar />
            <Layout />
        </div>
    );
};

export default Settings;