import React from 'react';

const CurrentInts = () => {
    return (
        <div>
            <h5 className={'fw-bold mb-3'}>Текущие интеграции</h5>

        </div>
    );
};

export default CurrentInts;