import React, {useEffect} from 'react';
import SignIn from "../components/Auth/SignIn";
import {useNavigate} from "react-router-dom";
import {whoAmIAPI} from "../api/auth/auth";
import {useDispatch} from "react-redux";
import {setUserReducer} from "../redux/reducers/user";

const Auth = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        whoAmIAPI().then(res => {
            if (res) {
                dispatch(setUserReducer(res))
                navigate('/dashboard')
            }
        })
    }, []);

    return (
        <div className={'bg-light h-100 w-100 d-flex align-items-center justify-content-center'}>
            <SignIn />
        </div>
    );
};

export default Auth;