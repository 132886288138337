import React, {useState} from 'react';
import {Button, Card, Form, InputGroup} from "react-bootstrap";
import {authAPI} from "../../api/auth/auth";
import {useNavigate} from "react-router-dom";

const SignIn = () => {
    const navigate = useNavigate()
    const [show, setShow] = useState(false)
    const [form, setForm] = useState({
        email: '',
        password: ''
    })

    const handleForm = (e) => {
        setForm(prev => Object.assign({...prev}, {[e.target.name]: e.target.value}))
    }

    const send = async (e) => {
        e.preventDefault()
       authAPI(form).then(res => {
           if (res) {
               navigate('Dashboard')
           }
           setForm({
               email: '',
               password: ''
           })
       })
    }

    return (
        <Card className={'border-0 shadow rounded-4'} style={{maxWidth: '350px'}}>
            <Card.Body className={'p-5'}>
                <div className={'text-center mb-5'}>
                    <img src={'/images/rnlegal.svg'} width={'100%'} alt={'logo'}/>
                </div>
                <Form onSubmit={send}>
                    <Form.Control name={'email'} className={'mb-3 shadow-none border p-3'} value={form.email} onChange={handleForm} placeholder={'Email'}/>
                    <InputGroup className={'d-flex align-items-center border rounded'}>
                        <Form.Control name={'password'} type={!show ? 'password' : 'text'} value={form.password} onChange={handleForm} className={'border-0 p-3 shadow-none'} placeholder={'Пароль'} />
                        <Button variant={'light'} onClick={() => setShow(!show)} className={`bg-transparent border-0 me-1 end-0 ${!show ? 'bi bi-eye-fill' : 'bi bi-eye-slash-fill'}`} />
                    </InputGroup>
                    <Form.Check label={'Запомнить меня'} className={'mt-2'} />
                    <Button type={'submit'} variant={'warning'} className={'w-100 py-2 mt-4'}>Войти</Button>
                </Form>
            </Card.Body>
            <Card.Footer className={'bg-warning border-0 py-3 text-center rounded-bottom-4'}>
                <b className={'small'}>© 2024 - cds@rosneft.ru</b>
            </Card.Footer>
        </Card>
    );
};

export default SignIn;