import React, {useEffect, useState} from 'react';
import {Table} from "rsuite";
import {useParams} from "react-router-dom";
import {createAnalyseAPI, getDocAnalysesAPI, updAnalyseAPI} from "../../../../api/dashboard/dashboard";
import {dateFormat} from "../../../../funcs/funcs";
import {Button, Form} from "react-bootstrap";
import CreateAnalyseModal from "./CreateAnalyseModal";

const Analyse = () => {
    const params = useParams()
    const columns = [
        {id: 'favorit', title: 'Доверять'},
        {id: 'fileName', title: 'Файл'},
        {id: 'analysatorName', title: 'Анализатор'},
        {id: 'created', title: 'Дата анализа'},
        {id: 'relevant', title: 'Релевантен'},
        {id: 'keywords', title: 'Ключевые слова'},
        {id: 'comment', title: 'Комментарии'},
    ]
    const [show, setShow] = useState(false)

    const [analyses, setAnalyses] = useState([])

    const showModal = () => {
        setShow(true)
    }

    const closeModal = () => {
        setShow(false)
    }

    const changeFavourite = (e, data) => {
        updAnalyseAPI(data.uuid, {favorit: e.target.checked}).then(res => {
            if (res) {
                setAnalyses(prev => prev.map(a => {
                    return a.uuid === res.uuid ? res : a
                }))
            }
        })
    }

    const createAnalyse = (form) => {
        createAnalyseAPI(form).then(res => {
            if (res) {
                setAnalyses(prev => [res, ...prev])
            }
        })
    }

    const getAction = (col, data) => {
        switch (col) {
            case 'fileName':
                return <p className={'m-0'} title={data?.file?.name}>{data?.file?.name}</p>
            case 'analysatorName':
                return data?.analyzer?.name
            case 'created':
                return dateFormat(data?.created)
            case 'relevant':
                return data.relevant ? 'Да' : 'Нет'
            case 'comment':
                return <p className={'m-0'} title={data.comment}>{data.comment}</p>
            case 'keywords':
                return <p className={'m-0'} title={data.keywords.join(',')}>{data.keywords.join(',')}</p>
            case 'favorit':
                return <Form.Check checked={data.favorit} onChange={(e) => changeFavourite(e, data)} />
            default:
                return ''
        }
    }

    const emptyTable = () => <div className={'h-100 w-100 d-flex align-items-center justify-content-center'}>
        <p className={'m-0'}>Анализы не были воспроизведены</p>
    </div>

    useEffect(() => {
        getDocAnalysesAPI(params.docId).then(res => {
            if (res) {
                setAnalyses(res.results)
            }
        })
    }, []);

    return (
        <div className={'flex-grow-1 overflow-hidden w-100'}>
            <div>
                <Button variant={'light'} onClick={showModal}>Сделать ручной анализ</Button>
            </div>
            <Table data={analyses} fillHeight renderEmpty={emptyTable}>
                {columns.map(c =>
                    <Table.Column key={c.id} flexGrow={c.id === 'comment' ? 3 : 1} resizable>
                        <Table.HeaderCell>
                            {c.title}
                        </Table.HeaderCell>
                        <Table.Cell>
                            {(rowData) => getAction(c.id, rowData)
                            }
                        </Table.Cell>
                    </Table.Column>
                )}
            </Table>
            <CreateAnalyseModal show={show} close={closeModal} create={createAnalyse} />
        </div>
    );
};

export default Analyse;