import React, {useEffect, useState} from 'react';
import {Button, Dropdown, Form, Modal} from "react-bootstrap";
import {getDocFilesAPI} from "../../../../api/dashboard/dashboard";
import {useParams} from "react-router-dom";
import {getAnalyzers, getListWordAPI, getModels} from "../../../../api/settings/settings";

const CreateAnalyseModal = ({show, close, create}) => {
    const params = useParams()
    const [files, setFiles] = useState([])
    const [analyzers, setAnalyzers] = useState([])
    const [models, setModels] = useState([])
    const [form, setForm] = useState({
        file_id: '',
        // aimodel_id: '',
        // analyzer_id: '',
        comment: '',
        relevant: false,
        favorit: false,
        error_returns: false,
        error_text: ''
    })

    const setFormFunc = (name, value) => {
        setForm(prev => Object.assign({...prev}, {[name]: value}))
    }

    const setChecks = (e) => {
        setForm(prev => Object.assign({...prev}, {[e.target.name]: e.target.checked}))
    }

    const save = () => {
        create(form)
        close()
        setForm({
            file_id: '',
            // aimodel_id: '',
            // analyzer_id: '',
            comment: '',
            relevant: false,
            favorit: false,
            error_returns: false,
            error_text: ''
        })
    }

    useEffect(() => {
        if (show) {
            getAnalyzers().then(res => {
                if (res) {
                    setAnalyzers(res.results)
                }
            })
            getModels().then(res => {
                if (res) {
                    setModels(res.results)
                }
            })
            getDocFilesAPI(params.docId).then(res => {
                if (res) {
                    setFiles(res.results)
                }
            })
        }
    }, [show]);

    return (
        <Modal show={show} onHide={close} backdropClassName={'second-backdrop'} className={'second-modal'}>
            <Modal.Header closeButton>Создание анализа</Modal.Header>
            <Modal.Body>
                <p className={'fw-bold mb-1'}>Файл</p>
                <Dropdown className={'mb-3'}>
                    <Dropdown.Toggle variant={'light'}
                                     className={'w-100 d-flex align-items-center justify-content-between'}>{files.find(f => f.uuid === form.file_id)?.name || 'Выберите файл'}</Dropdown.Toggle>
                    <Dropdown.Menu className={'w-100'}>
                        {files.map(f =>
                            <Dropdown.Item onClick={() => setFormFunc('file_id', f?.uuid)}>{f.name}</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
{/*                 <p className={'fw-bold mb-1'}>ИИ-модель</p>
                <Dropdown className={'mb-3'}>
                    <Dropdown.Toggle variant={'light'}
                                     className={'w-100 d-flex align-items-center justify-content-between'}>{models.find(f => f.uuid === form.aimodel_id)?.name || 'Выберите ИИ-модель'}</Dropdown.Toggle>
                    <Dropdown.Menu className={'w-100'}>
                        {models.map(f =>
                            <Dropdown.Item onClick={() => setFormFunc('aimodel_id', f?.uuid)}>{f.name}</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
                <p className={'fw-bold mb-1'}>Анализатор</p>
                <Dropdown className={'mb-3'}>
                    <Dropdown.Toggle variant={'light'}
                                     className={'w-100 d-flex align-items-center justify-content-between'}>{analyzers.find(f => f.uuid === form.analyzer_id)?.name || 'Выберите анализатор'}</Dropdown.Toggle>
                    <Dropdown.Menu className={'w-100'}>
                        {analyzers.map(f =>
                            <Dropdown.Item onClick={() => setFormFunc('analyzer_id', f?.uuid)}>{f.name}</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown> */}
                <p className={'fw-bold mb-1'}>Комментарий</p>
                <Form.Control as={'textarea'} className={'mb-3'} rows={4} value={form.comment}
                              onChange={(e) => setFormFunc('comment', e.target.value)}/>
                <div className={'d-flex gap-2 mb-3'}>
                    <span className={'fw-bold'}>Релевантен:</span>
                    <Form.Check type={'switch'}
                       checked={form.relevant}
                       name={'relevant'}
                       onChange={setChecks}
                    />
                </div>
                <div className={'d-flex gap-2 mb-3'}>
                    <span className={'fw-bold'}>Доверять:</span>
                    <Form.Check type={'switch'}
                       checked={form.favorit}
                       name={'favorit'}
                       onChange={setChecks}
                    />
                </div>
                <div className={'d-flex gap-2 mb-3'}>
                    <span className={'fw-bold'}>Выводить ошибку:</span>
                    <Form.Check type={'switch'}
                       checked={form.error_returns}
                       name={'error_returns'}
                       onChange={setChecks}
                    />
                </div>
                {form.error_returns &&
                    <div className={'mb-3'}>
                        <p className={'fw-bold mb-1'}>Текст ошибки</p>
                        <Form.Control as={'textarea'} rows={4} value={form.comment}
                                  onChange={(e) => setFormFunc('error_text', e.target.value)}/>
                    </div>
                }
                <Button variant={'success'} className={'float-end px-3'} onClick={save}>Создать</Button>
            </Modal.Body>
        </Modal>
    );
};

export default CreateAnalyseModal;
