import React from 'react';
import {Spinner} from "react-bootstrap";

const Loader = () => {
    return (
        <div className={'h-100 w-100 d-flex align-items-center justify-content-center'}>
            <Spinner variant={'primary'} />
        </div>
    );
};

export default Loader;