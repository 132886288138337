import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {whoAmIAPI} from "../api/auth/auth";
import {useNavigate} from "react-router-dom";
import {setUserReducer} from "../redux/reducers/user";
import Loader from "../components/Loader/Loader";

const UserProvider = ({children}) => {
    const user = useSelector((state) => state.user.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        whoAmIAPI().then(res => {
            if (res) {
                return dispatch(setUserReducer(res))
            }
            return navigate('/')
        })
    }, []);

    if (!user) {
        return <Loader />
    }
    return children;
};

export default UserProvider;