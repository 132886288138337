import {api} from "../api";

const urls = {
    auth: '/auth',
    exit: '/exit',
    whoami: '/whoami'
}



export const authAPI = async (form) => {
    try {
        const res = await api.post(urls.auth, form)
        const data = await res
        return data.data
    } catch (e) {

    }
}

export const whoAmIAPI = async () => {
    try {
        const res = await api.get(urls.whoami)
        const data = await res
        return data.data
    } catch (e) {

    }
}

export const exitAPI = async () => {
    try {
        const res = await api.post(urls.exit)
        const data = await res
        return data.data
    } catch (e) {

    }
}