import React, {useEffect, useState} from 'react';
import {Button, Dropdown, Form} from "react-bootstrap";
import {createAntIntAPI, getListWordAPI, getModels, getStopListAPI} from "../../../api/settings/settings";
import DropdownLists from "./ArtInts/DropdownLists";

const ArtInt = () => {
    const inputs = [
        {id: 'name', title: 'Имя *', description: 'Пользователи смогут выбрать этот анализатор на основе этого имени.'},
        {id: 'sysPrompt', title: 'Системный prompt *', description: 'Дайте общую информацию о том, что должен делать анализатор.  \n' +
                'Например: «Вы помощник дежурного инженера. Ваша цель — прочитать предоставленные контекстные документы и дать рекомендации по решению проблемы».'},
        {id: 'taskPrompt', title: 'Prompt выполняемой задачи *', description: 'Дайте конкретные инструкции о том, что делать с запросом.  \n' +
                'Например: «Найдите в предоставленных документах все соответствующие разделы, которые могут помочь пeользователю решить его проблему, и объясните, насколько они актуальны».'},
        // {id: 'pushCheck', title: 'Включить уведомления', description: 'Если найден релевантный документ бедет отправлено письмо на Email'},
        {id: 'listWordDropdown', title: 'Выбор словаря', description: 'Выберите, какой LLM использовать для этого персонажа.  Если оставить значение по умолчанию, будет использоваться  gpt-3.5-turbo-16k-0613.'},
        {id: 'stopWordDropdown', title: 'Выбор стоп слов', description: 'Выберите, какой LLM использовать для этого персонажа.  Если оставить значение по умолчанию, будет использоваться  gpt-3.5-turbo-16k-0613.'},
        {id: 'modelDropdown', title: 'Выбор модели *', description: 'Выберите, какой LLM использовать для этого персонажа.  Если оставить значение по умолчанию, будет использоваться  gpt-3.5-turbo-16k-0613.'},
        {id: 'key', title: 'Ключ API', description: 'Введите ключ API для доступа к модели (если выбран openai)'},
        {id: 'textSize', title: 'Размер текста', description: 'Укажите длину фрагментов текста для анализа'},
        {id: 'commentCheck', title: 'Включить комментарии', description: 'Если установлено, ответ будет включать комментарии для каждого документа.  Рекомендуем оставить это включенным, чтобы повысить доверие к ответу LLM.'},
        {id: 'filterCheck', title: 'Применить фильтр релевантности', description: 'Если этот параметр включен, анализатор будет отфильтровывать по ключевым словам словам фрагменты, не имеющие отношения к запросу пользователя.'}
    ]
    const [form, setForm] = useState({
        name: '',
        sysPrompt: '',
        taskPrompt: '',
        commentCheck: false,
        // pushCheck: false,
        listWordDropdown: '',
        stopWordDropdown: '',
        modelDropdown: '',
        key: '',
        textSize: '',
        filterCheck: false
    })
    const [models, setModels] = useState([])
    const [listWord, setListWord] = useState([])
    const [stopWord, setStopWord] = useState([])

    const handleInputs = (e) => {
        const regex = /^\d+$/
        if (e.target.name === 'textSize') {
            if (regex.test(e.target.value)) {
                return setForm(prev => Object.assign({...prev}, {[e.target.name]: e.target.value}))
            }
        }
        setForm(prev => Object.assign({...prev}, {[e.target.name]: e.target.value}))
    }

    const handleCheck = (e) => {
        console.log(e.target.checked);
        setForm(prev => Object.assign({...prev}, {[e.target.name]: e.target.checked}))
    }

    const handleModel = (i, m) => {
        setForm(prev => Object.assign({...prev}, {[i.id]: m.uuid}))
    }

    const getRequired = (i) => {
        switch (i) {
            case 'key':
                return models.find(m => m.uuid === form.model)?.slug === 'openai'
            case 'textSize':
                return false
            default:
                return true
        }
    }

    const saveAnalyser = (e) => {
        e.preventDefault()
        createAntIntAPI(form).then(res => {
            if (res) {
                setForm({
                    name: '',
                    sysPrompt: '',
                    taskPrompt: '',
                    commentCheck: false,
                    // pushCheck: false,
                    model: models.results.find(m => m?.slug === 'openai')?.uuid,
                    key: '',
                    textSize: '',
                    filter: false
                })
            }
        })
    }

    return (
        <div>
            <h5 className={'mb-4'}>Искусственный интеллект</h5>
            <form onSubmit={saveAnalyser}>
                {inputs.map(i =>
                    <div className={'mb-2'}>
                        {i.id.toLowerCase().includes('check') ?
                        <Form.Check className={'d-flex align-items-center'}>
                            <Form.Check.Input name={i.id} className={'me-2 shadow-none'} id={i.id} checked={form[i.id]} onChange={handleCheck} />
                            <Form.Check.Label for={i.id} className={'user-select-none'}>
                                <div>
                                    <p className={'fw-bold m-0'}>{i.title}</p>
                                    <p className={'small m-0 mb-1'}>{i.description}</p>
                                </div>
                            </Form.Check.Label>
                        </Form.Check>
                            : i.id.toLowerCase().includes('dropdown')?
                                <div>
                                    <p className={'fw-bold m-0'}>{i.title}</p>
                                    <p className={'small m-0 mb-1'}>{i.description}</p>
                                    <DropdownLists i={i} form={form} handleModel={handleModel} />
                                </div>
                                :
                                <div>
                                    <p className={'fw-bold m-0'}>{i.title}</p>
                                    <p className={'small m-0 mb-1'}>{i.description}</p>
                                <Form.Control required={getRequired(i.id)} className={'shadow-none'} name={i.id} value={form[i.id]} onChange={handleInputs} />
                            </div>
                        }
                    </div>
                )}
                <Button type={'submit'} variant={'success'} className={'px-4 py-2 mt-2'}>Создать анализатор</Button>
            </form>
        </div>
    );
};

export default ArtInt;