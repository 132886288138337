import { configureStore } from '@reduxjs/toolkit'
import user from "./reducers/user";
import statuses from "./reducers/statuses";
import docs from "./reducers/docs";
import docFilters from "./reducers/docFilters";

export const store = configureStore({
    reducer: {
        user: user,
        statuses: statuses,
        docs: docs,
        docFilters: docFilters
    },
})