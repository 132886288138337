import React, {useEffect, useState} from 'react';
import {Dropdown} from "react-bootstrap";
import {getListWordAPI, getModels, getStopListAPI} from "../../../../api/settings/settings";

const DropdownLists = ({i, form, handleModel}) => {
    const [arr, setArr] = useState([])

    const getAction = async (action) => {
        switch (action) {
            case 'listWordDropdown': {
                return await getListWordAPI()
            }
            case 'stopWordDropdown': {
                return await getStopListAPI()
            }
            default: {
                const res = await getModels()
                handleModel(i, res.results.find(r => r?.slug === 'openai'))
                return res
            }
        }
    }

    const handleDropdown = (m) => {
        handleModel(i, m)
    }

    useEffect(() => {
        getAction(i.id).then(res => {
            if (res) {
                setArr(res.results)
            }
        })
    }, []);

    return (
        <Dropdown>
            <Dropdown.Toggle variant={'light'} className={'w-100 d-flex align-items-center justify-content-between'}>{arr.find(m => m.uuid === form[i.id])?.name ?? 'Не выбран'}</Dropdown.Toggle>
            <Dropdown.Menu className={'w-100'}>
                {arr.map(m =>
                    <Dropdown.Item onClick={() => handleDropdown(m)}>
                        {m.name}
                    </Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default DropdownLists;