import {createSlice} from "@reduxjs/toolkit";

export const docs = createSlice({
    name: 'docs',
    initialState: {
        docs: [],
        info: {
            count: 0,
            next: '',
            prev: ''
        }
    },
    reducers: {
        setNewDocs: (state, action) => {
            state.info = {
                count: action.payload.count,
                next: action.payload.next,
                prev: action.payload.prev,
            }
            state.docs = action.payload.results
        },
        setDocsReducer: (state, action) => {
            state.info = {
                count: action.payload.count,
                next: action.payload.next,
                prev: action.payload.prev,
            }
            action.payload.results.forEach(d => {
                if (!state.docs.some(s => d.uuid === s.uuid)) {
                    state.docs = [...state.docs, d]
                }
            })
        },
        updDocReducer: (state, action) => {
            state.docs = state.docs.map(d => {
                d = action.payload.find(s => s.uuid === d.uuid) || d
                return d
            })
        }
    },
})

// Action creators are generated for each case reducer function
export const { setNewDocs, setDocsReducer, updDocReducer } = docs.actions

export default docs.reducer