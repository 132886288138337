import React, {useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {Link, useNavigate, useParams} from "react-router-dom";
import CurrentInts from "./Actions/CurrentInts";
import CreateInt from "./Actions/CreateInt";
import ArtInt from "./Actions/ArtInt";
import Words from "./Actions/Words";
import UserProvider from "../../providers/UserProvider";

const Layout = () => {
    const params = useParams()
    const navigate = useNavigate()


    const textWords = {
        listword: {title: 'Ключевые слова', header: 'Слова для поиска релевантных документов', description: 'Добавьте слова которые необходимо найти в документах и указать в документе'},
        stopword: {title: 'Стоп слова', header: 'Слова, которые будут удалены из файлов до анализа', description: 'Добавьте слова которые необходимо удалить из файлов до анализа'}
    }

    const getAction = () => {
        switch (params.action) {
            case 'currentints':
                return <CurrentInts />
            case 'createint':
                return <CreateInt />
            case 'artInt':
                return <ArtInt />
            case 'listword':
                return <Words text={textWords.listword} />
            case 'stopword':
                return <Words text={textWords.stopword} />
            default:
                return <CurrentInts />
        }
    }

    return (
        <UserProvider>
            <div className={'d-flex flex-grow-1 px-5 py-3 overflow-hidden'}>
                <div className={'flex-grow-1 d-flex rounded-4 bg-white shadow'}>
                    <Row className={'m-0 flex-grow-1'}>
                        <Col xs={3} className={'border-end h-100 py-3 px-4'}>
                            <h5 className={'mb-3'}>Интеграции</h5>
                            <Link className={'link mb-1'} to={'/settings/currentints'}>Текущие интеграции</Link>
                            <Link className={'link mb-1'} to={'/settings/createint'}>Создать интеграцию</Link>
                            <h5 className={'my-3'}>Управление документами</h5>
                            <Link className={'link mb-1'} to={'/settings/stopword'}>Стоп слова</Link>
                            <Link className={'link mb-1'} to={'/settings/createint'}>Иследование документов</Link>
                            <Link className={'link mb-1'} to={'/settings/createint'}>Обратная связь</Link>
                            <h5 className={'my-3'}>Помощники</h5>
                            <Link className={'link mb-1'} to={'/settings/artInt'}>Искусственный интеллект</Link>
                            <Link className={'link mb-1'} to={'/settings/listword'}>Ключевые слова</Link>
                            <Link className={'link mb-1'} to={'/settings/createint'}>Компьютерное зрение</Link>
                        </Col>
                        <Col className={'py-3 px-4 h-100 overflow-y-auto d-flex flex-column'}>
                            {getAction()}
                        </Col>
                    </Row>
                </div>
            </div>
        </UserProvider>
    );
};

export default Layout;