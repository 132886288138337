import React, {useEffect, useState} from 'react';
import {Button, Form} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {Table} from "rsuite";
import {
    createListWord,
    createStopWord, deleteListWord, deleteStopWord,
    getListWordAPI,
    getStopListAPI,
    updListWord, updStopWord
} from "../../../api/settings/settings";
import EditModal from "./Words/EditModal";

const Words = ({text}) => {
    const params = useParams()
    const columns = [{id: 'name', title: 'Название'}, {id: 'worlds', title: text.title}]
    const [info, setInfo] = useState({
        title: '',
        worlds: ''
    })
    const [data, setData] = useState([])
    const [editableItem, setEditableItem] = useState(null)

    const handleInfo = (e) => {
        setInfo(prev => Object.assign({...prev}, {[e.target.name]: e.target.value}))
    }

    const createItem = async () => {
        try {
            return params.action === 'stopword' ? await createStopWord(info) : await createListWord(info)
        } catch (e) {

        }
    }

    const saveData = (e) => {
        e.preventDefault()
        createItem().then(res => {
            if (res) {
                setData(prev => [res, ...prev])
                setInfo({
                    title: '',
                    worlds: ''
                })
            }
        })
    }

    const getData = async () => {
        try {
            return params.action === 'stopword' ? await getStopListAPI() : await getListWordAPI()
        } catch (e) {

        }
    }

    const closeEditModal = () => {
        setEditableItem(null)
    }

    const updData = async (info) => {
        try {
            return params.action === 'stopword' ? await updStopWord(info) : await updListWord(info)
        } catch (e) {

        }
    }

    const updItem = (info)=> {
        updData(info).then(res => {
            if (res) {
                setData(prev => prev.map(d => d.uuid === res.uuid ? res : d))
            }
        })
        closeEditModal()

    }

    const edit = (d) => {
        setEditableItem(d)
    }

    const deleteItem = async (d) => {
        try {
            const res = params.action === 'stopword' ? await deleteStopWord(d.uuid) : await deleteListWord(d.uuid)
            if (res) {
                setData(prev => prev.filter(p => p.uuid !== d.uuid))
            }
            return res
        } catch (e) {

        }
    }

    useEffect(() => {
        getData().then(res => {
            if (res) {
                setData(res.results)
            }
        })
    }, [params.action])

    return (
        <div className={'flex-grow-1 d-flex flex-column'}>
            <h5 className={'mb-3 fw-bold'}>{text.title}</h5>
            <p className={'m-0 fw-bold'}>{text.header}</p>
            <p className={'m-0'}>{text.description}</p>
            <div className={'mt-3'}>
                <form onSubmit={saveData}>
                    <p className={'fw-bold m-0 mb-1'}>Название</p>
                    <Form.Control required name={'name'} value={info.name} onChange={handleInfo} />
                    <p className={'fw-bold m-0 mt-2'}>Список слов</p>
                    <p className={'m-0 small mb-1'}>Введите слова через запятую</p>
                    <Form.Control required as={'textarea'} rows={4} name={'worlds'} value={info.words} onChange={handleInfo} />
                    <Button type={'submit'} variant={'success'} className={'mt-3 px-3'}>Создать</Button>
                </form>
            </div>
            <div className={'flex-grow-1 w-100 mt-3'}>
            <Table data={data} fillHeight shouldUpdateScroll={false}>
                {columns.map(col =>
                    <Table.Column flexGrow={col.id === 'worlds' ? 1 : 0} width={150}>
                        <Table.HeaderCell>{col.title}</Table.HeaderCell>
                        <Table.Cell>
                            {rowData =>
                                <div>{String(rowData[col.id])}</div>
                            }
                        </Table.Cell>
                    </Table.Column>
                )}
                <Table.Column>
                    <Table.HeaderCell><i className={'bi bi-pencil-square ms-2'}></i></Table.HeaderCell>
                    <Table.Cell>
                        {rowData =>
                            <div className={'d-flex align-items-center gap-2'}>
                                <Button size={'sm'} variant={'light'} className={'bi bi-pencil-square'} onClick={() => edit(rowData)} />
                                <Button size={'sm'} variant={'outline-danger'} className={'bi bi-trash'} onClick={() => deleteItem(rowData)} />
                            </div>
                        }
                    </Table.Cell>
                </Table.Column>
            </Table>
            </div>
            <EditModal show={!!editableItem} close={closeEditModal} item={editableItem} save={updItem} />
        </div>
    );
};

export default Words;