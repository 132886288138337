import {createSlice} from "@reduxjs/toolkit";

export const statuses = createSlice({
    name: 'statuses',
    initialState: {
        statuses: []
    },
    reducers: {
        setStatusesReducer: (state, action) => {
            state.statuses = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setStatusesReducer } = statuses.actions

export default statuses.reducer