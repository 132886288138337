import {createSlice} from "@reduxjs/toolkit";

export const user = createSlice({
    name: 'user',
    initialState: {
        user: null
    },
    reducers: {
        setUserReducer: (state, action) => {
            state.user = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setUserReducer } = user.actions

export default user.reducer