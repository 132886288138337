import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Form, Modal, Nav, Row, Spinner, Tab, Tabs} from "react-bootstrap";
import {getOneDocumentAPI, setStatusToDocAPI, updDocAPI} from "../../../api/dashboard/dashboard";
import {useParams} from "react-router-dom";
import {dateFormat} from "../../../funcs/funcs";
import {updDocReducer} from "../../../redux/reducers/docs";
import {useDispatch} from "react-redux";
import Files from "./DocModalElements/Files";
import Analyse from "./DocModalElements/Analyse";
import Users from "./DocModalElements/Users";

const DocModal = ({show, close}) => {
    const dispatch = useDispatch()
    const params = useParams()
    const [doc, setDoc] = useState(null)
    const actions = [
        {title: 'Комментарии', id: 'comments'},
        {title: 'История изменений', id: 'history'},
        {title: 'Анализ ИИ', id: 'analysys'},
        {title: 'Файлы', id: 'files'}
    ]
    const [action, setAction] = useState(actions[0])
    const [loading, setLoading] = useState(true)

    const getAction = () => {
        switch (action.id) {
            case 'comments':
                return 'Комменты'
            case 'history':
                return 'История'
            case 'analysys':
                return <Analyse />
            case 'files':
                return <Files />
            default:
                return 'Комменты'
        }
    }

    const handleDoc = (e) => {
        setDoc(prev => Object.assign({...prev}, {[e.target.name]: e.target.value}))
    }

    const saveDoc = () => {
        const newDoc = {name: doc.name, description: doc.description}
        updDocAPI(doc.uuid, newDoc).then(res => {
            if (res) {
                setDoc(res)
                dispatch(updDocReducer([res]))
            }
        })
    }
    const updStatus = (doc, s) => {
        setStatusToDocAPI(doc.uuid, s).then(res => {
            if (res) {
                setDoc(res)
                dispatch(updDocReducer([res]))
            }
        })
    }

    const updUsersFunc = (id, users) => {
        updDocAPI(id, {users: users}).then(res => {
            if (res) {
                setDoc(res)
                dispatch(updDocReducer([res]))
            }
        })
    }

    useEffect(() => {
        if (show) {
            getOneDocumentAPI(params.docId).then(res => {
                if (res) {
                    setDoc(res)
                    setLoading(false)
                }
            })
        }
    }, [show, params.docId]);

    return (
        <Modal show={show} onHide={close} size={'xl'} fullscreen>
            <Modal.Header closeButton className={'border-0 fw-bold'}>
                <span>Документ #{loading ? <Spinner size={'sm'} variant={'warning'} /> : doc?.id_document}</span>
            </Modal.Header>
            <Modal.Body className={'d-flex'}>
                {loading ?
                    <Spinner size={'sm'} variant={'warning'} />
                    :
                    <div className={'d-flex flex-grow-1'}>
                        <Row className={'flex-grow-1'}>
                            <Col xs={9} className={'h-100 d-flex flex-column'}>
                                <Form.FloatingLabel label={'Название'}>
                                    <Form.Control onBlur={saveDoc} name={'name'} value={doc.name} onChange={handleDoc} placeholder={'Название'} className={'mb-3'} />
                                </Form.FloatingLabel>
                                <Form.FloatingLabel label={'Описание'}>
                                    <Form.Control onBlur={saveDoc} value={doc.description} onChange={handleDoc}  name={'description'} as={'textarea'} row={5} placeholder={'Описание'} className={'mb-3'} />
                                </Form.FloatingLabel>
                                <Nav variant='pills' className='d-flex flex-row align-items-center border-bottom border-primary border-3 mb-3'>
                                    {actions.map(a =>
                                        <Nav.Item className={'me-2'}>
                                            <Button size={'sm'} className={'rounded-bottom-0'} onClick={() => setAction(a)} variant={action.id === a.id ? 'primary' : 'light'}>{a.title}</Button>
                                        </Nav.Item>
                                    )}
                                </Nav>
                                {getAction()}
                            </Col>
                            <Col xs={3} className={'h-100 d-flex flex-column overflow-y-auto'}>
                                <Users doc={doc} updStatus={updStatus} updUsersFunc={updUsersFunc} />
                                <div className={'shadow-sm p-3 border rounded'}>
                                    <p className={'fw-bold mb-3'}>Информация из источника</p>
                                    <p className={'mb-2'}>Источник: {doc?.integration?.name}</p>
                                    <span className={'mb-2'}>Ссылка:</span> <a href={doc?.integration?.url.replace('$', doc?.id_document)} target={'_blank'}>{doc?.integration?.url.replace('$', doc?.id_document)}</a>
                                    {/*<p className={'mb-2'}>Автор: {doc?.integration?.url}</p>*/}
                                    <p className={'mb-2'}>Дата создания: {dateFormat(doc?.created)}</p>
                                    <p className={'mb-2'}>Последнее изменение: {dateFormat(doc?.updated)}</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                }
            </Modal.Body>
        </Modal>
    );
};

export default DocModal;