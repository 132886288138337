import React, {useEffect} from 'react';
import NavBar from "../components/Navbar/NavBar";
import Layout from "../components/Dashboard/Layout";
import {whoAmIAPI} from "../api/auth/auth";
import {useDispatch} from "react-redux";
import {setUserReducer} from "../redux/reducers/user";
import {useNavigate} from "react-router-dom";
import {setStatusesReducer} from "../redux/reducers/statuses";
import {getStatusesAPI} from "../api/dashboard/dashboard";

const Dashboard = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        getStatusesAPI().then(res => {
            if (res) {
                dispatch(setStatusesReducer(res.results))
            }
        })
    }, []);

    return (
        <div className={'h-100 w-100 d-flex flex-column bg-light overflow-hidden'}>
            <NavBar />
            <Layout />
        </div>
    );
};

export default Dashboard;