import './App.css';
import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";
import Auth from "./pages/Auth";
import Dashboard from "./pages/Dashboard";
import Settings from "./pages/Settings";

function App() {

    return (
    <>
        <Routes>
          <Route path={'/'} element={<Auth />} />
            <Route path={'/dashboard'} element={<Dashboard />}>
                <Route path={':docId'} element={<Dashboard />} />
            </Route>
          <Route path={'/settings'} element={<Settings />}>
              <Route path={':action'} element={<Settings />} />
          </Route>
            <Route path={'/*'} element={<Auth />} />
        </Routes>
    </>
  );
}

export default App;
