import React, {useEffect, useRef, useState} from 'react';
import {Popover, Table, Whisper, Dropdown, Loader} from "rsuite";
import {getDocumentsAPI, setStatusToDocAPI} from "../../api/dashboard/dashboard";
import {Button, Form} from "react-bootstrap";
import columnsJSON from './columns.json'
import {useDispatch, useSelector} from "react-redux";
import {setDocsReducer, updDocReducer} from "../../redux/reducers/docs";
import {useNavigate, useParams} from "react-router-dom";
import DocModal from "./DocElements/DocModal";
import {dateFormatWithoutTime} from "../../funcs/funcs";

const DashTable = ({filters, analyzers, search, checkedDocs, setCheckedDocs, loading}) => {
    const params = useParams()
    const navigate = useNavigate()
    const { Column, HeaderCell, Cell } = Table;
    const columns = columnsJSON
    const tableRef = useRef()
    const dispatch = useDispatch()
    const docs = useSelector(state => state.docs.docs)
    const info = useSelector(state => state.docs.info)
    const statuses = useSelector(state => state.statuses.statuses)
    const [page, setPage] = useState(1)
    const [loadingMore, setLoadingMore] = useState(false)
    const [show, setShow] = useState(false)

    const empty = () => <div className={'h-100 w-100 d-flex align-items-center justify-content-center'}>Нет документов</div>

    const FixedLoader = () => (
        <Loader
            content="Загрузка..."
            style={{
                display: 'flex',
                justifyContent: 'center',
                position: 'absolute',
                bottom: '0',
                background: '#f1f1f1',
                width: '100%',
                padding: '4px 0'
            }}
        />
    );
    const LoadTable = () => (
        <Loader
            content="Загрузка..."
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                zIndex: 9999,
                background: '#e2e2e2',
                width: '100%',
                padding: '4px 0'
            }}
        />
    );

    const searchFilter = (d) => {
        const str = search.toLowerCase()
        return d.name.toLowerCase().includes(str) || d.description.toLowerCase().includes(str) || d.id_document.toLowerCase().includes(str)
    }

    const renderMenu = ({ onClose, left, top, className }, ref, doc) => {
        const handleSelect = eventKey => {
            onClose();
        };
        return (
            <Popover ref={ref} className={className} style={{ left, top }} full>
                <Dropdown.Menu onSelect={handleSelect}>
                    {statuses.map(s =>
                    <Dropdown.Item eventKey={s.slug} onClick={() => setStatusToDocument(doc, s)}>{s.name}</Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Popover>
        );
    };

    const ActionCell = ({col, doc}) => {
        return (
            <Whisper placement="autoVerticalStart" trigger="click" speaker={(e, ref) => renderMenu(e, ref, doc)}>
                <Button variant={'light'} size={'sm'}>{doc?.status?.name}</Button>
            </Whisper>
        );
    };
    const setStatusToDocument = (d, s) => {
        setStatusToDocAPI(d.uuid, s).then(res => {
            if (res) {
                dispatch(updDocReducer([res]))
            }
        })
    }

    const isAllChecked = (d) => {
        return !d.map(doc => checkedDocs.some(c => c.uuid === doc.uuid)).some(b => !b) && d.length > 0;
    }

    const checkAll = (e, d) => {
        console.log(e.target.checked)
        if (e.target.checked) {
            setCheckedDocs(prev => [...prev, ...d.filter(doc => !prev.some(p => p.uuid === doc.uuid))])
        } else {
            setCheckedDocs(prev => prev.filter(doc => !d.some(p => p.uuid === doc.uuid)))
        }
    }
    const checkDoc = (e, d) => {
        if (e.target.checked) {
            setCheckedDocs(prev => [...prev, d])
        } else {
            setCheckedDocs(prev => prev.filter(p => p.uuid !== d.uuid))
        }
    }

    const getAction = (col, doc) => {
        switch (col) {
            case 'id_document':
                return <a href={doc?.integration?.url.replace('$', String(doc[col]))} target={'_blank'}>{String(doc[col])}</a>
            case 'status':
                return (
                    <ActionCell col={col} doc={doc} />
                )
            case 'relevant':
                return doc?.relevant ? 'Да' : 'Нет'
            case 'word':
                return <p className={'m-0'} title={doc?.word.join(',')}>{doc?.word.join(',')}</p>
            case 'integration':
                return doc?.integration?.name
            case 'publication_date':
                return dateFormatWithoutTime(doc?.publication_date)
            default:
                return <p className={'m-0'} title={String(doc[col])}>{String(doc[col])}</p>
        }
    }

    const getMoreDocs = () => {
        let newPage = page+1
        getDocumentsAPI(filters, search, analyzers, newPage).then(res => {
            if (res?.results.length > 0) {
                dispatch(setDocsReducer(res))
                setPage(newPage)
            }
            setLoadingMore(false)
        })
    }

    const handleScroll = (x, y) => {
        const contextHeight = docs.length * 46;
        const top = Math.abs(y);
        console.log(contextHeight - top - tableRef.current.offsetHeight < 0 && !loadingMore && docs.length < info.count)
        if (contextHeight - top - tableRef.current.offsetHeight < 0 && !loadingMore && docs.length < info.count) {
            setLoadingMore(true)
            getMoreDocs();
        }
    }

    const showModal = (e, doc, c) => {
        const activeCols = ['id_document', 'status']
        if (e.metaKey || e.ctrlKey) {
            return checkDoc({target: {checked: true}}, doc)
        }
        if (e.shiftKey) {
            let start = Math.min(checkedDocs.map(d => docs.indexOf(d)))
            let end = docs.indexOf(doc)+1
            if (checkedDocs.length > 0) {
                return setCheckedDocs(structuredClone(docs).slice(start, end))
            } else {
                return checkDoc({target: {checked: true}}, doc)
            }
        }
        if (!activeCols.includes(c.param)) {
            navigate(`/dashboard/${doc.uuid}`)
        }
    }

    const closeModal = () => {
        setShow(false)
        navigate('/dashboard')
    }

    const getFlexGrow = (c) => {
        switch (c) {
            case 'name':
                return 5
            case 'word':
                return 2
            case 'status':
                return 2
            case 'integration':
                return 2
            case 'publication_date':
                return 2
            default:
                return 0
        }
    }

    useEffect(() => {
        if (params.docId) {
            setShow(true)
        }
    }, [params.docId]);

    return (
        <div ref={tableRef} className={'position-relative flex-grow-1 bg-white rounded-4 border overflow-hidden'}>
            <Table virtualized
                   onScroll={handleScroll} shouldUpdateScroll={false} data={docs} fillHeight className={''} loading={loading} renderLoading={LoadTable} renderEmpty={empty}>
                <Column width={50}>
                    <HeaderCell>
                        <Form.Check className={'shadow-none'} checked={isAllChecked(docs)} onChange={(e) => checkAll(e, docs)} />
                    </HeaderCell>
                    <Cell>
                        {(rowData, rowIndex) =>
                            <Form.Check className={'shadow-none'} checked={checkedDocs.some(d => d.uuid === rowData.uuid)} onChange={(e) => checkDoc(e, rowData)} />
                        }
                    </Cell>
                </Column>
                {columns.map(c =>
                    <Column flexGrow={getFlexGrow(c.param)} resizable>
                        <HeaderCell>
                            {c.title}
                        </HeaderCell>
                        <Cell className={'p-0 d-flex align-items-center justify-content-center'}>
                            {(rowData, rowIndex) =>
                                <div className={'h-100 w-100 d-flex align-items-center'} onContextMenu={e => e.preventDefault()} onClick={(e) => showModal(e, rowData, c)}>
                                    {getAction(c.param, rowData)}
                                </div>
                            }
                        </Cell>
                    </Column>
                )}
            </Table>
            {loadingMore && <FixedLoader />}
            <DocModal show={show} close={closeModal} />
        </div>
    );
};

export default DashTable;